var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('details-page-title',{attrs:{"title":_vm.$t('components.offers.marketplace.details.headline'),"dropdown-text-button":"Campaign Status","details-id":_vm.id}},[_c('div',{staticClass:"flex items-center gap-4 mt-4 md:mt-0"},[_c('button',{staticClass:"\n              flex\n              items-center\n              justify-between\n              px-4\n              py-0\n              font-medium\n              border\n              rounded-full\n              shadow-sm                \n              h-35px\n              text-14px text-oWhite\n              focus:border-border-oWhite                \n              focus:outline-none\n              \n            ",class:[
          _vm.details.status === 'Active'
            ? 'bg-blue-600 hover:bg-blue-700'
            : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed' ],on:{"click":function($event){return _vm.onEdgeStack({
            esId: _vm.invite.esId,
            primaryKey: _vm.id,
            rawData: _vm.details,
          })}}},[_c('div',{staticClass:"flex items-center "},[_c('i',{staticClass:"mr-2 fas fa-paper-plane"}),_c('span',[_vm._v(" Notify Riders ")])])]),_c('MoreActions',{attrs:{"current-status":_vm.details.is_active},on:{"change":_vm.UpdateStatus}})],1)]),_c('DetailsPageHeader',{attrs:{"rawData":_vm.indexMetaData.extra_data.campaign}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitleButton',{attrs:{"title":_vm.$t('components.offers.marketplace.details.subHeadline'),"hideButton":true}})],1)]),_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
          var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
          var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{attrs:{"text":("#" + (item.id)),"truncate":-5,"to":{
                  name: 'MarketingCampaignNotifyDetailsIndex',
                  params: { id: item.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":_vm.getPushOptions(item.push_option)}}),_c('FSTableRowItem',{attrs:{"text":item.updated_at,"date":""}}),_c('FSTableRowItem',{attrs:{"text":item.summary.total_invited}}),_c('FSTableRowItem',[_c('div',[_c('span',[_vm._v(_vm._s(item.summary.success_percentage)+"%("+_vm._s(item.summary.succeeded)+")")]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar-fillable"}),_c('div',{staticClass:"bar-fill bg-green-600",style:(("max-width: " + (item.summary.success_percentage) + "%; "))})])])]),_c('FSTableRowItem',[_c('div',[_c('span',[_vm._v(_vm._s(item.summary.failure_percentage)+"%("+_vm._s(item.summary.failed)+")")]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar-fillable"}),_c('div',{staticClass:"bar-fill bg-red-600",style:(("max-width: " + (item.summary.failure_percentage) + "%; "))})])])])],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":("#" + (item.id)),"truncate":-5,"to":{
                  name: 'MarketingCampaignNotifyDetailsIndex',
                  params: { id: item.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.summary.total_invited}}),_c('FSTableRowItem',[_c('div',[_c('span',[_vm._v(_vm._s(item.summary.success_percentage)+"%("+_vm._s(item.summary.succeeded)+")")]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar-fillable"}),_c('div',{staticClass:"bar-fill bg-green-600",style:(("max-width: " + (item.summary.success_percentage) + "%; "))})])])]),_c('FSTableRowItem',[_c('div',[_c('span',[_vm._v(_vm._s(item.summary.failure_percentage)+"%("+_vm._s(item.summary.failed)+")")]),_c('div',{staticClass:"bar"},[_c('div',{staticClass:"bar-fillable"}),_c('div',{staticClass:"bar-fill bg-red-600",style:(("max-width: " + (item.summary.failure_percentage) + "%; "))})])])])],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.offers.marketplace.details.table.columns.type' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getPushOptions(item.push_option))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" "+_vm._s(_vm.$t( 'components.offers.marketplace.details.table.columns.date' ))+" ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(_vm.getFormattedDateTime( item.updated_at, 'D MMM YYYY, h:mm:ss a' ))+" ")])])])]):_vm._e()]:_vm._e()]})]}}])}),_c('NotifyRider',{attrs:{"esId":_vm.invite.esId,"busy":_vm.invite.busy,"riderData":_vm.rider,"rawData":_vm.details,"fleets":_vm.fleets,"primaryKey":_vm.invite.primaryKey,"fleetList":_vm.fleetList,"currencies":_vm.currencies},on:{"save":function($event){return _vm.$store.dispatch('fsTable/fetchData')},"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: $event.taskId })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }