<template>
  <content-section>
    <div class=" flex flex-col md:flex-row mt-4 gap-20">
      <div class="w-full md:w-2/5">
        <CampaignDetailsCard
          ref="CampaignDetailsCard"
          :description="getCampaignDescription"
          :title="getCampaignTitle"
        />
      </div>
      <div class="w-full md:w-3/5">
        <table :id="`riderTable`" class="fst">
          <tr class="fst-row" v-if="showCampaignIdHyperlinked">
            <th class="fst-head-row-item">Campaign Id</th>
            <td class="fst-row-item">
              <RouterLink
                :to="{
                  name: 'MarketingCampaignDetailsIndex',
                  params: { id: form.id },
                }"
                class="text-blue-600 capitalize"
              >
                #{{ $truncate(form.id, -5) }}
              </RouterLink>
            </td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.offers.marketplace.details.title.campaignExpireDate'
                )
              }}
            </th>
            <td class="fst-row-item">{{ form.expires_at }}</td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{ $t('components.offers.marketplace.details.title.medium') }}
            </th>
            <td class="fst-row-item">{{ getPushOptions }}</td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t('components.offers.marketplace.details.title.assignedFleets')
              }}
            </th>
            <td class="fst-row-item">{{ getLocation }}</td>
          </tr>
        </table>
      </div>
    </div>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import CampaignDetailsCard from './CampaignDetailsCard.vue'
export default {
  name: 'VoucherDetailsPageHeader',
  props: {
    rawData: {
      type: Object,
      default: () => null,
    },
    currencySymbol: {
      type: String,
      default: () => '',
    },
    showCampaignIdHyperlinked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: null,
      dateFormat: {
        format: 'DD MMM YY',
      },
    }
  },
  components: {
    ContentSection,
    CampaignDetailsCard,
  },
  watch: {
    rawData: {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data }
          this.form.expires_at = this.$UTCAwareTime(data.expires_at, {
            format: 'DD MMM YY',
          })
        }
      },
    },
  },
  computed: {
    getCampaignTitle() {
      return this.form?.title
    },
    getCampaignDescription() {
      return this.form?.description
    },
    getLocation() {
      let fleets = this.form?.assigned_fleets
      let locations = []
      if (fleets && Array.isArray(fleets)) {
        locations = fleets.map((fleet) => fleet.name)
      }
      return locations.join(', ')
    },

    getPushOptions() {
      const PushOptions = {
        PO_SMS: 'SMS',
        PO_EMAIL: 'Email',
        PO_PUSH: 'Push',
      }

      return this.form?.push_options.map((item) => PushOptions[item]).join(', ')
    },
  },
}
</script>

<style lang="scss" scoped>
.fst-container {
  @apply overflow-x-auto;

  & .fst-wrapper {
    min-height: 30vh;
    height: 100%;

    @apply relative inline-block min-w-full overflow-hidden;
  }
}

.fst {
  @apply min-w-full leading-normal;

  & .fst-head {
    @apply shadow-md;
  } // .fst-head

  .fst-head-row {
    @apply shadow-md border-b border-gray-200;
    //
  }
  .fst-head-row-item {
    @apply pt-2 pb-2 font-bold tracking-wider text-left text-14px text-oDark outline-none focus:outline-none;

    &:first-child,
    &:last-child {
      @apply px-3;
    }
  }
  & .fst-row {
    @apply border-b border-gray-100;

    &:nth-child(odd) {
      background-color: #f7fafc;
      // background-color: #fafdff;
    }

    // row data
    & .fst-row-item {
      color: #6d6d75;

      // @apply  pr-3 font-medium text-14px transform;
      @apply py-2 font-medium text-14px;

      &:first-child,
      &:last-child {
        @apply px-3;
      }
    }

    // row-data hover effect
    &:hover .fst-row-item {
      // background-color: #f5fff5;
    }
  }

  .fst-foot {
    //
  } // .fst-foot
}
.action {
  display: none;
  font-size: 10px;
}
.fst-row:hover .action {
  display: flex;
}
</style>
