var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('details-page-title',{attrs:{"title":"Notified Marketing Campaign","details-id":_vm.id}}),_c('DetailsPageHeader',{attrs:{"showCampaignIdHyperlinked":true,"rawData":_vm.indexMetaData.extra_data.push_record.campaign}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitleButton',{attrs:{"title":"Push Record Summary","hideButton":true}})],1),_c('div',{staticClass:"summary-card-container"},_vm._l((_vm.getSummaries),function(summary){return _c('SummaryCard',{key:"SummaryCard-" + summary.title,attrs:{"title":summary.title,"value":summary.value,"variant":summary.variant,"icon":summary.variant}})}),1)]),_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.getTableHeaders,"endpoint":_vm.indexDataEndpoint,"text-fallback-always":true,"searchEnabled":false},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return [_vm._l((data),function(item,itemIndex){return [(_vm.getTableMode === 'full')?[_c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{attrs:{"text":("#" + (item.id)),"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.rider.full_name,"to":{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.rider.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.rider.device_os}}),_c('FSTableRowItem',{attrs:{"text":item.rider.ip_address}}),_c('FSTableRowItem',[_c('TimeAgo',{attrs:{"title":item.updated_at,"datetime":item.updated_at,"auto-update":60}})],1),_c('FSTableRowItem',{attrs:{"text":item.sent_failure_reason || "--"}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status)}})],1)],1)]:_vm._e(),(_vm.getTableMode === 'responsive')?[_c('FSTableRow',{key:("fs-table-row-" + itemIndex),attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',[_c('div',{staticClass:"col-span-1 focus:text-gray-400",on:{"click":function($event){return _vm.toggle(itemIndex)}}},[(_vm.opened.includes(itemIndex))?_c('i',{staticClass:"fas fa-minus-circle",staticStyle:{"color":"#d90a20"}}):_c('i',{staticClass:"fas fa-plus-circle"})])]),_c('FSTableRowItem',{attrs:{"text":item.rider.full_name,"to":{
                  name: 'ViewRiderUserProfile',
                  params: { id: item.rider.id },
                }}}),_c('FSTableRowItem',{attrs:{"text":item.rider.ip_address}}),_c('FSTableRowItem',[_c('x-status',{attrs:{"text":item.status,"variant":_vm.getItemVariant(item.status)}})],1)],1),(_vm.opened.includes(itemIndex))?_c('FSTableRow',{key:itemIndex},[_c('td',{attrs:{"colspan":"10"}},[_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" Invitation ID ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" #"+_vm._s(_vm._f("onlyLastFive")(item.id))+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" Device OS ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.rider.device_os || '--')+" ")])]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" Last Attempted ")]),_c('div',{staticClass:"col-span-5 right-text"},[_c('TimeAgo',{attrs:{"title":item.updated_at,"datetime":item.updated_at,"auto-update":60}})],1)]),_c('div',{staticClass:"grid grid-cols-10 items-center w-full expand-item"},[_c('div',{staticClass:"col-span-5 left-text"},[_vm._v(" Failed Reason ")]),_c('div',{staticClass:"col-span-5 right-text"},[_vm._v(" "+_vm._s(item.sent_failure_reason || "--")+" ")])])])]):_vm._e()]:_vm._e()]})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }