// merge summary count with component data
export function formatSummaries(summaries) {
  const generateSummaryOptions = (title, variant, icon = null) => ({
    title,
    variant,
    icon,
  })
  const generateSummaryDetails = (summaryOption, value) => ({
    ...summaryOption,
    value,
  })

  const summaryOptions = {
    total_pushed_campaign: generateSummaryOptions('Invited', 'gray'),
    total_sent_campaign: generateSummaryOptions('Success', 'gray'),
    total_failed_campaign: generateSummaryOptions('Failed', 'gray'),
    // total_expired_campaign: generateSummaryOptions('Expired', 'purple'),
  }

  const formattedSummaries = []
  for (const k in summaryOptions) {
    formattedSummaries.push(
      generateSummaryDetails(summaryOptions[k], summaries[k])
    )
  }

  return formattedSummaries
}
