<template>
  <div class="flex items-center font-semibold text-oDark text-28px">
    <slot>{{ title }}</slot>
    <div
      v-if="!hideButton"
      class="flex items-center justify-center  ml-2 rounded-full cursor-pointer oto-plus-bg"
      @click="handleClickingButton()"
    >
      <div
        class="button-box px-2 py-1"
        :class="`${getVariantClass} ${getProfileClass}`"
      >
        {{ buttonText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TitleButton',
  props: {
    title: {
      type: String,
    },
    hideButton: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Button',
    },
    variant: {
      type: String,
      default: 'blue',
    },
    profile: {
      type: String,
      default: 'base',
    },
  },
  computed: {
    getVariantClass: function() {
      const validVariants = [
        'orange',
        'green',
        'red',
        'blue',
        'yellow',
        'light-blue',
        'purple',
        'gray',
        'blush',
        'black',
        'alt-gray',
        'alt-blue',
        'alt-green',
        'ultra-green',
        'alt-orange',
        'alt-red',
      ]
      if (validVariants.includes(this.variant)) {
        return `variant-${this.variant}`
      }
      return 'variant-orange'
    },
    getProfileClass() {
      return `profile-${this.profile}`
    },
  },
  methods: {
    handleClickingButton() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.button-box {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  height: 19px;
  border-radius: 3px;

  &.profile-base {
    width: auto;
    height: auto;
  }

  &.variant-alt-gray {
    color: #7e8494;
    background-color: #e3e8ee;
  }
  &.variant-alt-blue {
    color: #5f72bf;
    background-color: #d7ebff;
  }
  &.variant-alt-green {
    color: #09aa35;
    background-color: #d1fae5;
  }
  &.variant-ultra-green {
    color: #ffffff;
    background-color: #22c55e;
  }
  &.variant-alt-red {
    color: #d73434;
    background-color: #ffd1d1;
  }
  &.variant-alt-orange {
    color: #ffa500;
    background-color: #fffbcc;
  }

  &.variant-orange {
    background-color: #f67606;
  }
  &.variant-yellow {
    background-color: #ffed00;
    color: #030303;
  }
  &.variant-blue {
    background-color: #0053ff;
  }
  &.variant-light-blue {
    background-color: #4aaafd;
  }
  &.variant-green {
    background-color: #09aa35;
  }
  &.variant-red {
    background-color: #d90a20;
  }
  &.variant-purple {
    background-color: #7d3cf8;
  }
  &.variant-gray {
    background-color: #b4b9bc;
  }
  &.variant-blush {
    background-color: #dc143c;
  }
  &.variant-black {
    @apply bg-gray-800;
  }
}
</style>
