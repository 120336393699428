<template>
  <base-layout>
    <details-page-title title="Notified Marketing Campaign" :details-id="id">
    </details-page-title>
    <DetailsPageHeader
      :showCampaignIdHyperlinked="true"
      :rawData="indexMetaData.extra_data.push_record.campaign"
    />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitleButton title="Push Record Summary" :hideButton="true" />
        </div>

        <div class="summary-card-container">
          <SummaryCard
            v-for="summary in getSummaries"
            :key="`SummaryCard-` + summary.title"
            :title="summary.title"
            :value="summary.value"
            :variant="summary.variant"
            :icon="summary.variant"
          />
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        :searchEnabled="false"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />

                <FSTableRowItem
                  :text="item.rider.full_name"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.rider.id },
                  }"
                />
                <FSTableRowItem :text="item.rider.device_os" />
                <FSTableRowItem :text="item.rider.ip_address" />
                <FSTableRowItem>
                  <TimeAgo
                    :title="item.updated_at"
                    :datetime="item.updated_at"
                    :auto-update="60"
                  />
                </FSTableRowItem>
                <FSTableRowItem :text="item.sent_failure_reason || `--`" />

                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="item.rider.full_name"
                  :to="{
                    name: 'ViewRiderUserProfile',
                    params: { id: item.rider.id },
                  }"
                />
                <FSTableRowItem :text="item.rider.ip_address" />
                <FSTableRowItem>
                  <x-status
                    :text="item.status"
                    :variant="getItemVariant(item.status)"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      Invitation ID
                    </div>
                    <div class="col-span-5 right-text">
                      #{{ item.id | onlyLastFive }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      Device OS
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.rider.device_os || '--' }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      Last Attempted
                    </div>
                    <div class="col-span-5 right-text">
                      <TimeAgo
                        :title="item.updated_at"
                        :datetime="item.updated_at"
                        :auto-update="60"
                      />
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      Failed Reason
                    </div>
                    <div class="col-span-5 right-text">
                      {{ item.sent_failure_reason || `--` }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import { formatSummaries } from './helpers'
import DetailsPageHeader from './DetailsPageHeader.vue'
import XStatus from '@/components/badge/XStatus'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import { MarketingCampaignConfig } from '@/config/MarketingCampaignConfig'
import ContentSection from '@/components/layout/ContentSection'
import TitleButton from '@/components/ui/TitleButton.vue'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'MarketingCampaignNotifyDetailsIndex',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    SummaryCard,
    TitleButton,
    ContentSection,
  },
  data() {
    return {
      details: {},
      indexMetaData: {
        summary: {
          total_expired_campaign: 0,
          total_failed_campaign: 0,
          total_pushed_campaign: 0,
          total_sent_campaign: 0,
        },
        extra_data: {
          push_record: {
            campaign: {},
          },
        },
      },
      qso: { append: '', prepend: '?' },
      fstId: 'notifyDetails',

      id: '',
      indexDataEndpoint: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        { text: 'Invitation ID', width: '10%', sort: null },
        { text: 'Rider Name', width: '30%', sort: null },
        { text: 'Device OS', width: '15%', sort: null },
        { text: 'IP Address', width: '20%', sort: null },
        { text: 'Last Attempted', width: '10%', sort: null },
        { text: 'Failed Reason', width: '20%', sort: null },
        { text: 'Status', width: '10%', sort: null },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        { text: 'Rider Name', width: '35%', sort: null },
        { text: 'IP Address', width: '35%', sort: null },
        { text: 'Status', width: '20%', sort: null },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
      fleets: [],
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleetList: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = MarketingCampaignConfig.api.notifyHistory(this.id)
    this.getVoucherDetails()
    this.getFleets()
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getPushOptions(type) {
      const pushOptions = {
        PO_SMS: 'SMS',
        PO_EMAIL: 'Email',
        PO_PUSH: 'Push',
      }

      return pushOptions[type]
    },
    getItemVariant(status) {
      if (status === 'Success') return 'green'
      if (status === 'Failed') return 'red'
      return 'blue'
    },

    onApplyFilterDateRange,
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
