<template>
  <div>
    <div class="flex w-auto  md:items-center md:justify-end md:mt-0">
      <t-dropdown variant="smActions">
        <template
          v-slot:trigger="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
          }"
        >
          <button
            :class="getButtonClass"
            aria-label="Voucher Actions"
            aria-haspopup="true"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
          >
            <loading
              :height="25"
              :opacity="0"
              color="#fff"
              v-if="lockReq"
              :active="true"
              loader="bars"
              :is-full-page="false"
            />
            <span v-else>
              <i class="mr-2 fas fa-chevron-down"></i>
              {{ getStatusText }}
            </span>
          </button>
        </template>

        <template v-slot:default="{ hide }">
          <div @click="hide()" class="py-1 mt-px bg-white rounded-md shadow-lg">
            <t-dropdown-item
              class="font-medium text-14px"
              @click="handleVehicleLockUnlock(true)"
            >
              Active
            </t-dropdown-item>
            <t-dropdown-item
              class="font-medium text-14px"
              @click="handleVehicleLockUnlock(false)"
            >
              Inactive
            </t-dropdown-item>
          </div>
        </template>
      </t-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MoreActions',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },

  props: {
    currentStatus: {
      type: Boolean,
      reqired: false,
    },
  },

  watch: {
    currentStatus: {
      immediate: true,
      deep: false,
      handler(v) {
        this.status = v
      },
    },
  },

  data() {
    return {
      lockReq: false,
      status: false,
    }
  },

  computed: {
    getStatusText() {
      return this.status ? 'Active' : 'Inactive'
    },

    getButtonClass() {
      let c = 'voucher-action-button emphasize '
      // color
      if (this.status) {
        c += 'green '
      } else {
        c += 'gray'
      }
      return c
    },
  },

  methods: {
    async handleVehicleLockUnlock(status) {
      if (this.lockReq === true) {
        return
      }
      this.lockReq = true
      this.$emit('change', status)
      this.lockReq = false
    },
  },
}
</script>

<style lang="scss" scoped>
.voucher-action-button {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;

  font-size: 14px;
  font-weight: 500;

  &:focus {
    outline: none;
  }

  // colors
  &.green {
    @apply bg-oGreen;
  }
  &.orange {
    background-color: #ffb100;
  }
  &.gray {
    background-color: #c7c7c7;
  }

  // variants
  &.badge {
    width: 110px;
    height: 20px;
    border-radius: 3px;
  }

  &.emphasize {
    width: 155px;
    @apply flex items-center justify-between shadow-md rounded-full h-35px;
    @apply px-3 py-0 pl-3 text-center cursor-pointer;

    &.inactive {
      @apply bg-gray-300;
    }
  }
}
</style>
