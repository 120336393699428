<template>
  <base-layout>
    <details-page-title
      :title="$t('components.offers.marketplace.details.headline')"
      dropdown-text-button="Campaign Status"
      :details-id="id"
    >
      <div class="flex items-center gap-4 mt-4 md:mt-0">
        <button
          @click="
            onEdgeStack({
              esId: invite.esId,
              primaryKey: id,
              rawData: details,
            })
          "
          class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
          :class="[
            details.status === 'Active'
              ? 'bg-blue-600 hover:bg-blue-700'
              : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
          ]"
        >
          <div class="flex items-center ">
            <i class="mr-2 fas fa-paper-plane"></i>
            <span> Notify Riders </span>
          </div>
        </button>
        <MoreActions
          :current-status="details.is_active"
          @change="UpdateStatus"
        />
      </div>
    </details-page-title>
    <DetailsPageHeader :rawData="indexMetaData.extra_data.campaign" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitleButton
            :title="$t('components.offers.marketplace.details.subHeadline')"
            :hideButton="true"
          />
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="getTableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" textFallbackAlways>
                <FSTableRowItem
                  :text="`#${item.id}`"
                  :truncate="-5"
                  :to="{
                    name: 'MarketingCampaignNotifyDetailsIndex',
                    params: { id: item.id },
                  }"
                />
                <FSTableRowItem :text="getPushOptions(item.push_option)" />
                <FSTableRowItem :text="item.updated_at" date />
                <FSTableRowItem :text="item.summary.total_invited" />
                <FSTableRowItem>
                  <div>
                    <span
                      >{{ item.summary.success_percentage }}%({{
                        item.summary.succeeded
                      }})</span
                    >
                    <div class="bar">
                      <div class="bar-fillable" />
                      <div
                        class="bar-fill bg-green-600"
                        :style="
                          `max-width: ${item.summary.success_percentage}%; `
                        "
                      />
                    </div>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div>
                    <span
                      >{{ item.summary.failure_percentage }}%({{
                        item.summary.failed
                      }})</span
                    >
                    <div class="bar">
                      <div class="bar-fillable" />
                      <div
                        class="bar-fill bg-red-600"
                        :style="
                          `max-width: ${item.summary.failure_percentage}%; `
                        "
                      />
                    </div>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem
                  :text="`#${item.id}`"
                  :truncate="-5"
                  :to="{
                    name: 'MarketingCampaignNotifyDetailsIndex',
                    params: { id: item.id },
                  }"
                />
                <FSTableRowItem :text="item.summary.total_invited" />
                <FSTableRowItem>
                  <div>
                    <span
                      >{{ item.summary.success_percentage }}%({{
                        item.summary.succeeded
                      }})</span
                    >
                    <div class="bar">
                      <div class="bar-fillable" />
                      <div
                        class="bar-fill bg-green-600"
                        :style="
                          `max-width: ${item.summary.success_percentage}%; `
                        "
                      />
                    </div>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem>
                  <div>
                    <span
                      >{{ item.summary.failure_percentage }}%({{
                        item.summary.failed
                      }})</span
                    >
                    <div class="bar">
                      <div class="bar-fillable" />
                      <div
                        class="bar-fill bg-red-600"
                        :style="
                          `max-width: ${item.summary.failure_percentage}%; `
                        "
                      />
                    </div>
                  </div>
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.offers.marketplace.details.table.columns.type'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{ getPushOptions(item.push_option) }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.offers.marketplace.details.table.columns.date'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          item.updated_at,
                          'D MMM YYYY, h:mm:ss a'
                        )
                      }}
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
      <NotifyRider
        :esId="invite.esId"
        :busy="invite.busy"
        :riderData="rider"
        :rawData="details"
        :fleets="fleets"
        :primaryKey="invite.primaryKey"
        :fleetList="fleetList"
        :currencies="currencies"
        @save="$store.dispatch('fsTable/fetchData')"
        @edgestack="
          onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
        "
      />
    </content-section>
  </base-layout>
</template>

<script>
import { formatSummaries } from './helpers'
import DetailsPageHeader from './DetailsPageHeader.vue'
import xMan from '@/utils/xMan'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
// import SummaryCard from '@/components/cards/SummaryCard'
import { MarketingCampaignConfig } from '@/config/MarketingCampaignConfig'
import ContentSection from '@/components/layout/ContentSection'
import TitleButton from '@/components/ui/TitleButton.vue'
import NotifyRider from '../NotifyRider'
import MoreActions from './MoreActions'
// import ProgressBarX from '@/components/analytics/charts/ProgressBarX.vue'
import { useEdgeStack } from '@/plugins/edgeStack'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'MarketingCampaignDetailsIndex',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    // SummaryCard,
    TitleButton,
    ContentSection,
    NotifyRider,
    MoreActions,
    // ProgressBarX,
  },
  data() {
    return {
      details: {},
      indexMetaData: {
        summary: {
          total_expired_campaign: 0,
          total_failed_campaign: 0,
          total_pushed_campaign: 0,
          total_sent_campaign: 0,
        },
        extra_data: {
          campaign: {},
        },
      },
      invite: {
        esId: 'notifyRider',
        busy: false,
        rawData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherSummary',
      filterOptions: [
        {
          key: 'push_option',
          type: 'checkbox',
          input: [
            { text: 'Email', value: 'PO_EMAIL' },
            { text: 'Push', value: 'PO_PUSH' },
            { text: 'SMS', value: 'PO_SMS' },
          ],
          title: 'Type',
        },
      ],
      id: '',
      indexDataEndpoint: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.notifyID'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.type'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.date'
          ),
          width: '30%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.invited'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.success'
          ),
          width: '15%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.failed'
          ),
          width: '15%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.notifyID'
          ),
          width: '20%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.invited'
          ),
          width: '10%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.success'
          ),
          width: '25%',
          sort: null,
        },
        {
          text: this.$t(
            'components.offers.marketplace.details.table.columns.failed'
          ),
          width: '25%',
          sort: null,
        },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
      fleets: [],
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleetList: [],
      currencies: [],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = MarketingCampaignConfig.api.campaignHistory(
      this.id
    )
    this.getVoucherDetails()
    this.getFleets()
  },
  methods: {
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getPushOptions(type) {
      const pushOptions = {
        PO_SMS: 'SMS',
        PO_EMAIL: 'Email',
        PO_PUSH: 'Push',
      }

      return pushOptions[type]
    },
    async getFleets() {
      let { data } = await this.$http.get('/dashboard/fleets/?dropdown')
      if (data) {
        this.fleetList = data.data
        const currencyList = []
        data.data.forEach((fleet) => {
          const result = currencyList.find(
            (row) => fleet.country.code3 === row.text
          )
          if (!result) {
            currencyList.push({
              text: fleet.country.code3,
              value: fleet.country.currency_symbol,
            })
          }
        })
        this.currencies = currencyList
      }
    },
    async onEdgeStack({
      esId = this.invite.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
      rawData = null,
    } = {}) {
      const edgeStack = useEdgeStack()
      this.rider.rider = ''
      this.rider.invitation_type = false
      this.rider.assigned_fleets = []

      // open the edgestack first, load data asynchronally if primary key is provided
      if (esAction === 'toggle') {
        edgeStack.toggle(esId)
      } else if (esAction === 'open') {
        edgeStack.open(esId)
      } else if (esAction === 'close') {
        edgeStack.close(esId)
      } else {
        throw new Error(`Unknown esAction: ${esAction}`)
      }

      // immidiately make edgestack busy & fetch & provide data if primary key is provided
      if (primaryKey && esId === this.invite.esId) {
        // view mode
        this.invite.busy = true
        this.invite.primaryKey = primaryKey
        this.invite.rawData = rawData
        this.getFleetOptions()
      }
    },
    getFleetOptions() {
      let fleets = this.details?.assigned_fleets.map(function(row) {
        return {
          text: row.name,
          value: row.id,
        }
      })
      this.fleets = fleets
    },
    onApplyFilterDateRange,
    getRedeemedAt(redeemed_at) {
      if (redeemed_at) {
        return this.$UTCAwareTime(redeemed_at, this.dateFormat)
      }
      return '--'
    },
    getItemVarient(status) {
      if (status === 'Success') return 'green'
      if (status === 'Failed') return 'red'
      return 'blue'
    },
    async getVoucherDetails() {
      let { data } = await this.$http.get(
        MarketingCampaignConfig.api.detail(this.id)
      )
      if (data) {
        this.details = data
      }
    },
    async UpdateStatus(status) {
      this.isLoading = true
      let data = new xMan({ is_active: status }).toFormData()
      await this.$http
        .patch(MarketingCampaignConfig.api.update(this.id), data)
        .then((res) => {
          console.log(res)
          this.getVoucherDetails()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Campaign status has updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('id-ver-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update status',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.bar {
  height: 5px;
  width: 100%;
  color: white;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}

.bar-fillable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: #ecf0f1;
}

.bar-fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  transition: 0.7s;
  animation: bar-fill 1 1.8s;
}

// .bar-fill {
//   max-width: 60%;
// }

@-webkit-keyframes bar-fill {
  0% {
    width: 0px;
  }
  100% {
    width: 100%;
  }
}
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
